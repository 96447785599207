import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, tap, map, switchMap } from 'rxjs/operators';
import { VoucherStatus, PandaDocDocument } from '../models/pandadoc.types';
import { environment } from '@env/environment';
import CONFIG from '@app/app.config';
import { VoucherDto, VoucherResponse } from '@app/models/voucher.model';

@Injectable({
  providedIn: 'root'
})
export class PandaDocService {
  private readonly statusCache = new Map<string, BehaviorSubject<VoucherStatus>>();
  private readonly CACHE_LIFETIME = 5 * 60 * 1000; // 5 minutes in milliseconds

  constructor(private readonly http: HttpClient) {}

  /**
   * Get full document details from PandaDoc
   */
  getDocument(documentId: string, organizationId: string): Observable<PandaDocDocument> {
    console.log(`[PandaDocService] Getting document details for ${documentId}`);
    
    return this.http
      .get<PandaDocDocument>(
        `${CONFIG.API}/pandadoc/organizations/${organizationId}/documents/${documentId}`
      )
      .pipe(
        map((response) => ({
          ...response,
          metadata: {
            ...response.metadata,
            created_at: response.metadata?.created_at || response.createdAt
          }
        })),
        tap((response) => console.log(`[PandaDocService] Document details retrieved for ${documentId}:`, response)),
        catchError((error) => {
          console.error(`[PandaDocService] Error fetching document ${documentId}:`, error);
          return throwError(() => error);
        })
      );
  }

  /**
   * Get all vouchers for an organization
   */
  getAllVouchers(organizationId: string): Observable<VoucherDto[]> {
    console.log(`[PandaDocService] Getting all vouchers for organization ${organizationId}`);
    
    return this.http
      .get<VoucherResponse[]>(`${CONFIG.API}/pandadoc/organizations/${organizationId}/vouchers`)
      .pipe(
        map((response) =>
          response.map((item) => ({
            contract_number: item.contract_number,
            contract_period: item.contract_period,
            created_at: item.created_at,
            document_id: item.document_id,
            id: item.id,
            metadata: item.metadata,
            organization_id: item.organization_id,
            panda_doc_id: item.panda_doc_id,
            status: item.status,
            supplier_name: item.supplier_name,
            updated_at: item.updated_at,
            workflow_status: item.workflow_status,
            voucher_period: item.voucher_period
          }))
        ),
        tap((vouchers) => console.log(`[PandaDocService] Retrieved ${vouchers?.length || 0} vouchers`)),
        catchError((error) => {
          console.error(`[PandaDocService] Error fetching vouchers:`, error);
          return throwError(() => error);
        })
      );
  }

  /**
   * Clear cache for a specific document or all documents
   */
  clearCache(documentId?: string) {
    if (documentId) {
      this.statusCache.delete(documentId);
    } else {
      this.statusCache.clear();
    }
  }
} 