import { NbMenuItem } from '@nebular/theme';
import { environment } from '@env/environment';

const isRCDSS = environment.name === 'rcdss';


export let MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'Notifications',
    icon: { icon: 'dashboard-icon', pack: 'lasgro-icons' },
    link: '/dashboard',
  },
  {
    title: 'Messages',
    icon: { icon: 'envelope', pack: 'font-awesome' },
    link: '/dashboard/my-messages',
  },
  {
    title: 'Reports',
    icon: { icon: 'line-chart', pack: 'font-awesome' },
    link: '/reports',
    data: {
      forRole: 'Company Admin',
      forCompanyType: 'customer',
    },
  },
  {
    title: 'My Organization',
    icon: { icon: 'company-icon', pack: 'lasgro-icons' },
    link: '/organization',
  },
  {
    title: 'Organization Mails',
    icon: { icon: 'mail', pack: 'lasgro-icons' },
    link: '/organization/mails',
  },
  {
    title: 'Org. Workflows',
    icon: { icon: 'sitemap', pack: 'font-awesome' },
    link: '/workflows',
    data: {
      forRole: 'Lasgro Admin',
    },
  },
  {
    title: 'Organizations',
    icon: { icon: 'cog', pack: 'font-awesome' },
    link: '/organization/companies',
    data: {
      forRole: 'Lasgro Admin',
    },
  },
  {
    title: 'Org. Settings',
    icon: { icon: 'cog', pack: 'font-awesome' },
    link: '/organization/my-company',
    data: {
      forRole: 'Lasgro Admin',
    },
  },

  {
    title: '',
    group: true,
  },

  {
    title: 'New Contract',
    icon: { icon: 'plus', pack: 'font-awesome' },
    link: '/purchase-order/create-simple',
    data: {
      forCompanyType: 'customer',
    },
  },
  {
    title: 'Contract(s)',
    icon: { icon: 'pos-issued-icon', pack: 'lasgro-icons' },
    link: '/customers/work-commissioned',
    data: {
      forCompanyType: 'customer',
    },
  },
  {
    title: isRCDSS ? 'Vendors' : 'My Suppliers',
    icon: { icon: 'my-suppliers-icon', pack: 'lasgro-icons' },
    link: '/customers/my-suppliers',
    data: {
      forCompanyType: 'customer',
    },
  },

  {
    title: '',
    group: true,
  },

  {
    title: 'Contract(s)',
    icon: { icon: 'download-icon', pack: 'lasgro-icons' },
    link: '/suppliers/work-in-progress',
    data: {
      forCompanyType: 'supplier',
    },
  },
  {
    title: 'DSS',
    icon: { icon: 'businessman-icon', pack: 'lasgro-icons' },
    link: '/organization/customers',
    data: {
      forCompanyType: 'supplier',
    },
  },

  {
    title: '',
    group: true,
    data: {
      forCompanyType: 'supplier',
    },
  },

  {
    title: 'IT Help',
    icon: { icon: 'question', pack: 'font-awesome' },
    link: '/help/it-help',
  },
  {
    title: 'Training Guides',
    icon: { icon: 'info', pack: 'font-awesome' },
    link: '/help/training-guides',
  },

  {
    title: '',
    group: true,
  },

  {
    title: 'Log out',
    icon: { icon: 'sign-out', pack: 'font-awesome' },
    link: '/auth/logout',
  },
];
